body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #151d20;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  font-family: "Poppins", sans-serif;
}

.container {
  padding: 0rem 15vw;
}
.container-left {
  padding: 0 0 0rem 15vw;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0rem 7vw;
  }
  .container-left {
    padding: 0 0 0rem 7vw;
  }
}
@media screen and (max-width: 768px) {
  .container {
    padding: 0rem 5vw;
  }
  .container-left {
    padding: 0 5vw 0rem 5vw;
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 1;
    transform: scale(1.25);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fade-in {
  0% {
    opacity: 1;
    z-index: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0) scale(1.1);
    transform: translateX(0) scale(1.1);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-100%) scale(1.1);
    transform: translateX(-100%) scale(1.1);
    visibility: hidden;
  }
}

@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0) scale(1.1);
    transform: translateX(0) scale(1.1);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(-100%) scale(1.1);
    transform: translateX(-100%) scale(1.1);
    visibility: hidden;
  }
}

@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0) scale(1.1);
    transform: translateX(0) scale(1.1);
    transform: translateX(100%) scale(1.1);
  }
  100% {
    -webkit-transform: translateX(100%) scale(1.1);
    transform: translateX(100%) scale(1.1);
  }
}

@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0) scale(1.1) F;
    transform: translateX(0) scale(1.1);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateX(100%) scale(1.1);
    transform: translateX(100%) scale(1.1);
    visibility: hidden;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}